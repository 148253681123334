import React, { FunctionComponent, Fragment } from 'react'
import { graphql } from 'gatsby'
import { TaggedPostsQuery } from '../graphqlTypes'
import Layout from '../components/Layout'
import PostExcerpt from '../components/PostExcerpt'
import ContentBox from '../components/ContentBox'
import Seo from '../components/Seo'

interface PageContext {
  tag: string
}

interface Props {
  data: TaggedPostsQuery
  pageContext: PageContext
}

export const pageQuery = graphql`
  query TaggedPosts($skip: Int!, $limit: Int!, $tag: String!) {
    posts: allFile(filter: {sourceInstanceName: {eq: "posts"}, internal: {mediaType: {eq: "text/markdown"}}, childMarkdownRemark: {frontmatter: {tags: {glob: $tag}}}}, sort: {order: DESC, fields: ctimeMs}, limit: $limit, skip: $skip) {
      edges {
        post: node {
          childMarkdownRemark {
            id
            frontmatter {
              title
              tags
              slug
              date
              headerImageAlt
              headerImage {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            excerpt
            timeToRead
          }
          relativeDirectory
        }
      }
    }
  }
`

const getSlug = (relativeDirectory: string, slug?: string | null): string => (slug !== null) ? slug as string : relativeDirectory

const PostList: FunctionComponent<Props> = ({ pageContext, data: { posts: { edges: posts } } }: { data: TaggedPostsQuery, pageContext: PageContext }) => {
  return (
    <Layout>
      <Seo title={`Posts tagged with '${pageContext.tag}'`} />
      <ContentBox><h2>Posts tagged with <span className='tagName'>{pageContext.tag}</span></h2></ContentBox>
      {posts.map(({ post }, i) => {
        const slug = getSlug(post.relativeDirectory, post.childMarkdownRemark?.frontmatter?.slug)
        return (
          <Fragment key={i}>
            <PostExcerpt
              slug={slug}
              title={post.childMarkdownRemark?.frontmatter?.title as string}
              excerpt={post.childMarkdownRemark?.excerpt as string}
              timeToRead={post.childMarkdownRemark?.timeToRead as number}
              tags={post.childMarkdownRemark?.frontmatter?.tags}
              headerImage={{
                fluid: post.childMarkdownRemark?.frontmatter?.headerImage?.childImageSharp?.fluid,
                alt: post.childMarkdownRemark?.frontmatter?.headerImageAlt
              }}
              date={post.childMarkdownRemark?.frontmatter?.date as Date}
            />
          </Fragment>
        )
      })}
    </Layout>
  )
}

export default PostList
